/* eslint-disable no-unsafe-optional-chaining */
import { chain, map } from 'lodash';
import React, { useState } from 'react';
import { useFormState } from 'react-final-form';
import { Badge, Button, Table } from 'reactstrap';
import { Menus } from '../../../../resbutler-utils/types/Menu';
import { Product, ProductSizes, StockLimits } from '../../../../resbutler-utils/types/product';
import ProductForm from '../common/ProductForm';

function ProductComponent({ product, menus, restaurantId, productSizes }) {
  const [collapse, setCollapse] = useState({});
  const values = useFormState().values as StockLimits;

  // manage accordion for product
  function toggleCollapse(tab) {
    const tab_state = collapse?.[tab] ?? false;
    setCollapse({ ...collapse, [tab]: !tab_state });
  }

  const enabledProductSizes = product.sizes
    ? chain(product.sizes)
        .pickBy((enabled, sizeId) => enabled && productSizes[sizeId])
        .map((enabled, sizeId) => sizeId)
        .value()
    : [];
  const [_sizeId, setSizeId] = useState('');
  const sizeId = _sizeId || enabledProductSizes[0] || '';
  return (
    <React.Fragment key={product.id}>
      <tr>
        <td>
          <Button type="button" className="mr-2" onClick={() => toggleCollapse(product.id)}>
            <i className={!collapse[product.id] ? 'fa fa-chevron-right' : 'fa fa-chevron-down'} aria-hidden="true" />
          </Button>
          {product.name}
          <Badge color="light" className="ml-1">
            {product.id in values?.products && Object.values(values.products[product.id]).some(({ isOverride = false }) => !!isOverride) ? 'override' : ''}
          </Badge>
        </td>
      </tr>
      {collapse[product.id] ? (
        <tr className="p-2">
          {enabledProductSizes.length > 0 && (
            <>
              <select
                className="form-control"
                onChange={(e) => {
                  setSizeId(e.currentTarget.value);
                }}
              >
                {map(enabledProductSizes, (sizeId: string) => (
                  <option value={sizeId}>{productSizes[sizeId].name}</option>
                ))}
              </select>
              {!sizeId ? null : <ProductForm stockLimitKey="products" productId={product.id} menus={menus} key={sizeId} size={productSizes[sizeId]} restaurantId={restaurantId} />}
            </>
          )}
        </tr>
      ) : null}
    </React.Fragment>
  );
}

interface ProductsComponentProps {
  menus: Menus;
  restaurantId: string;
  productSizes: ProductSizes;
  filteredProducts: Product[];
}

function ProductsComponent({ menus, restaurantId, productSizes, filteredProducts }: ProductsComponentProps) {
  return (
    <React.Fragment>
      <Table bordered className="sc-table">
        <tbody>
          {map(filteredProducts, (product: Product) => (
            <ProductComponent key={`menu-item-stock-override-product-${product.id}`} product={product} menus={menus} restaurantId={restaurantId} productSizes={productSizes} />
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default ProductsComponent;
