import { combineReducers } from 'redux';
import { createSelector } from 'reselect';
import { CHANGE_THEME, TOGGLE_SUMMARY } from '../actions/actions';
import { StateType } from './reducers';
export interface ThemeReducerTypes {
  path: string;
  showSummary: boolean;
}

const initialState = {
  path: 'themes/theme-b.css',
  showSummary: null,
} as ThemeReducerTypes;

function changeTheme(state = initialState.path, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return action.path;
    default:
      return state;
  }
}

export function showSummary(state = initialState.showSummary, action) {
  switch (action.type) {
    case TOGGLE_SUMMARY:
      localStorage.setItem(TOGGLE_SUMMARY, JSON.stringify(action.showSummary));
      return action.showSummary;
    default:
      return state;
  }
}

export const getToggleSummarySelector = createSelector(
  (state: StateType) => state.theme.showSummary,
  () => {
    const toggleSummaryLocal = localStorage.getItem(TOGGLE_SUMMARY);
    return toggleSummaryLocal ? JSON.parse(toggleSummaryLocal) : false;
  }
);

export default combineReducers({
  changeTheme,
  showSummary,
});
