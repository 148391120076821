import { CashDrawer } from "./CashDrawer";

export const INCH2_WIDTH = 42;
export const INCH3_WIDTH = 48;

export enum TPaperWidth {
  INCH2 = "inch2",
  INCH3 = "inch3",
}

export const paperWidths = {
  [TPaperWidth.INCH2]: INCH2_WIDTH,
  [TPaperWidth.INCH3]: INCH3_WIDTH,
};

export interface Printer {
  id?: string;
  name: string;
  macAddress: string;
  ipAddress: string;
  port: number;
  paperWidth: TPaperWidth;
  enabled?: boolean;
  cashDrawer?: CashDrawer;
  restaurantId: string;
  isImpactPrinter?: boolean;
  printerAreas?: string[];
  showCustomerName?: boolean;
  showExtraSpacing?: boolean;
  groupOrdersByTable?: boolean;
  printNodeId?: number;
}

export interface Printers {
  [printerId: string]: Printer;
}

export interface PrintersRouters {
  [printerId: string]: { routePrinterId: string; disabled: boolean };
}
