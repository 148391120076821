export enum BookingType {
  Preview = 0,
  QROrderingOnly = 1,
  QRNewBooking = 2,
  Standard = 3,
  Takeaway = 4,
  Function = 5,
}

export enum BookingClass {
  Booking = "Booking",
  FunctionBooking = "FunctionBooking",
}
