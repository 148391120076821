import { OperationMode } from '../components/Layout/Offsidebar';
import { OrderItemStatus } from '../resbutler-utils/types/Order';
import { DisplayType, ProgressType } from '../components/Layout/OmsSelector';
import { DocketFromDocketDocketItems } from '../resbutler-utils/types/Docket';

export const isDocketCompleted = (docket, operationMode, display) => {
  if (operationMode === OperationMode.OneStep) {
    const completedItems = (docket.docketItems as DocketFromDocketDocketItems[]).filter((di) => di.orderStatus === OrderItemStatus.Served);
    const removedItems = (docket.docketItems as DocketFromDocketDocketItems[]).filter((di) => di.orderStatus === undefined);
    return completedItems.length > 0 && completedItems.length + removedItems.length === docket.docketItems.length;
  } else {
    if (display === DisplayType.PrepDisplay) {
      const completedItems = (docket.docketItems as DocketFromDocketDocketItems[]).filter((di) => di.orderStatus >= OrderItemStatus.Prepared);
      const removedItems = (docket.docketItems as DocketFromDocketDocketItems[]).filter((di) => di.orderStatus === undefined);
      return completedItems.length > 0 && completedItems.length + removedItems.length === docket.docketItems.length;
    } else if (display === DisplayType.ServeDisplay) {
      const completedItems = (docket.docketItems as DocketFromDocketDocketItems[]).filter((di) => di.orderStatus === OrderItemStatus.Served);
      const removedItems = (docket.docketItems as DocketFromDocketDocketItems[]).filter((di) => di.orderStatus === undefined);
      return completedItems.length > 0 && completedItems.length + removedItems.length === docket.docketItems.length;
    }
  }

  return false;
};

export const isDocketRemoved = (docket) => (docket.docketItems as DocketFromDocketDocketItems[]).every((di) => di.orderStatus === undefined);

export const getCompleteStatus = (display: DisplayType, progress: ProgressType, operationMode: OperationMode, currentOrderStatus: OrderItemStatus) => {
  if (operationMode === OperationMode.OneStep) {
    if (progress === ProgressType.All || progress === ProgressType.Active) {
      if (currentOrderStatus < OrderItemStatus.Served) return OrderItemStatus.Served;
    }
  } else if (operationMode === OperationMode.AllStep) {
    if (display === DisplayType.PrepDisplay) {
      if (progress === ProgressType.All || progress === ProgressType.Active) {
        if (currentOrderStatus < OrderItemStatus.Prepared) return OrderItemStatus.Prepared;
      }
    } else if (display === DisplayType.ServeDisplay) {
      if (progress === ProgressType.All || progress === ProgressType.Active) {
        if (currentOrderStatus < OrderItemStatus.Served) return OrderItemStatus.Served;
      }
    }
  }
  return OrderItemStatus.Served;
};

export const getResetStatus = (display: DisplayType, progress: ProgressType, operationMode: OperationMode, currentOrderStatus: OrderItemStatus) => {
  if (operationMode === OperationMode.OneStep) {
    if (progress === ProgressType.All || progress === ProgressType.Completed) {
      if (currentOrderStatus === OrderItemStatus.Served) return OrderItemStatus.Sent;
    }
  } else if (operationMode === OperationMode.AllStep) {
    if (display === DisplayType.PrepDisplay) {
      if (progress === ProgressType.All || progress === ProgressType.Completed) {
        if (currentOrderStatus === OrderItemStatus.Prepared) return OrderItemStatus.Sent;
      }
    } else if (display === DisplayType.ServeDisplay) {
      if (progress === ProgressType.All || progress === ProgressType.Completed) {
        if (currentOrderStatus === OrderItemStatus.Served) return OrderItemStatus.Prepared;
      }
    }
  }
  return OrderItemStatus.Sent;
};
