import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { catchExceptionCallback, getConfig } from '../core/utilities';
import { AdditionGroup, Modifier, PreparationGroup, UpsellGroup } from '../resbutler-utils/types/product';
import { setAdditionGroups as setAdditionGroupsAction, setModifierGroups as setModifierGroupsAction, setPreparationGroups as setPreparationGroupsAction, setUpsellGroups as setUpsellGroupsAction } from '../store/reducers/rootSlice';

const useProductAmmendments = (firebaseLoading): [boolean, Modifier[], AdditionGroup[], UpsellGroup[], PreparationGroup[]] => {
  const dispatch = useDispatch();
  const [modifierGroups, setModifiersGroups] = useState<Modifier[]>([]);
  const [additionGroups, setAdditionGroups] = useState<AdditionGroup[]>([]);
  const [upsellGroups, setUpsellGroups] = useState<UpsellGroup[]>([]);
  const [preparationGroups, setPreparationGroups] = useState<PreparationGroup[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetch() {
      try {
        setLoading(true);
        const { client } = getConfig();
        const modifierGroupsQuery = query(collection(getFirestore(), `${client}/products/modifierGroups`), where('enabled', '==', true));
        const additionGroupsQuery = query(collection(getFirestore(), `${client}/products/additionGroups`), where('enabled', '==', true));
        const preparationGroupsQuery = query(collection(getFirestore(), `${client}/products/preparationGroups`), where('enabled', '==', true));
        const upsellGroupsQuery = query(collection(getFirestore(), `${client}/products/upsellGroups`), where('enabled', '==', true));
        const [snap1, snap2, snap3, snap4] = await Promise.all([getDocs(modifierGroupsQuery), getDocs(additionGroupsQuery), getDocs(preparationGroupsQuery), getDocs(upsellGroupsQuery)]);
        const modifierGroups = snap1.docs.map((d) => ({ id: d.id, ...(d.data() as Modifier) }));
        const additionalGroups = snap2.docs.map((d) => ({ id: d.id, ...(d.data() as AdditionGroup) }));
        const preparationGroups = snap3.docs.map((d) => ({ id: d.id, ...(d.data() as PreparationGroup) }));
        const upsellGroups = snap4.docs.map((d) => ({ id: d.id, ...(d.data() as UpsellGroup) }));
        setModifiersGroups(modifierGroups);
        setAdditionGroups(additionalGroups);
        setPreparationGroups(preparationGroups);
        setUpsellGroups(upsellGroups);

        dispatch(setModifierGroupsAction(modifierGroups));
        dispatch(setAdditionGroupsAction(additionalGroups));
        dispatch(setPreparationGroupsAction(preparationGroups));
        dispatch(setUpsellGroupsAction(upsellGroups));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }
    if (!firebaseLoading) fetch();
  }, [firebaseLoading]);

  return [loading, modifierGroups, additionGroups, upsellGroups, preparationGroups];
};
export default useProductAmmendments;
