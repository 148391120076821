export enum MessageTypes {
  BOOKING_NO_SHOW = 1,
  FUNCTION_MAESTRO = 2,
  BOOKING_MAESTRO = 3,
  FUNCTION_NO_SHOW = 4,
  BOOKING_WIDGET = 5,
  FUNCTION_WIDGET = 6,
  CAMPAIGN = 7,
  CUSTOMER_VERIFICATION = 8,
  BOOKING_CONFIRMATION = 9,
  BOOKING_STANDBY = 10,
  TAB_GUEST_ADDED = 11,
  TAB_CLOSED = 12,
  EXPRESS_MESSAGE = 13,
  BUCKET_EXPIRED = 14,
}

// sendCampaignMessage
// type: item.smsResponse.destination ? 3 : 5,

export interface MessageDocument {
  url: string;
  fileName: string;
  name: string;
  ext: string;
  mimeType: string;
}
export interface MessageImage {
  url: string;
  thumbUrl: string;
  sizeFile: number;
  sizeThumb: number;
  fileName: string;
  mimeType: string;
  name: string;
  ext: string;
}

export interface Message {
  bookingId?: string;
  functionId?: string;
  completed: boolean;
  customerId: string;
  date?: number;
  dateTimeRef: number;
  incoming: boolean;
  mealId: string;
  mobile: string;
  restaurantId: string;
  type: MessageTypes;
  status: "status" | "received" | "sent";
  id?: string;
  isRead?: boolean;
  message: string;
  document?: MessageDocument;
  image?: MessageImage;
  fromTime?: number;
  toTime?: number;
}

export interface Messages {
  [messageId: string]: Message;
}
