import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { catchExceptionCallback, getConfig } from '../core/utilities';
import { Menus } from '../resbutler-utils/types/Menu';
import { setMenus } from '../store/reducers/rootSlice';
import useUrlParams from './useUrlParams';

function useMenus(firebaseLoading) {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [restaurantId] = useUrlParams();
  
  useEffect(() => {
    const fetchAllMenus = async () => {
      try {
        const { client } = getConfig();
        setLoading(true);
        const querySnapshot = await firebase.firestore().collection(`${client}/menus/menus`).where('restaurantId', '==', restaurantId).where('enabled', '==', true).get();
        let docData: Menus = {};
        querySnapshot.docs.forEach((doc) => {
          docData = Object.assign(docData, { [doc.id]: doc.data() });
        });
        dispatch(setMenus(docData));
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    };
    if (restaurantId && !firebaseLoading) fetchAllMenus();
  }, [restaurantId, firebaseLoading]);

  return [loading];
}

export default useMenus;
