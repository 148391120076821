import _, { chain, filter, find, forEach, map } from "lodash";
import moment from "moment";
import { BookingType } from "../types";
import { Booking } from "../types/Booking";
import { OMSDisplayDocket } from "../types/Docket";
import { FunctionBooking } from "../types/FunctionBooking";
import { CourseGroup, MenuHeadings } from "../types/Menu";
import { Bucket, Docket, DocketItem, DocketItemCombo, DocketItems, DocketPrintStatus, DocketType, OrderItem, OrderItemProducts, OrderModifier, OrderModifiers, OrderType, Orders } from "../types/Order";
import { Printers } from "../types/Printers";
import { Customer } from "../types/customer";
import { Product, ProductGroups, ProductSizes, Products } from "../types/product";
import { getTableNumbersOfBooking } from "./bookingUtils";
import { sortComboGroupProducts, sortOrderModiferProducts } from "./productUtils";

interface ConsolidatedOrderItem extends OrderItem {
  consolidatedItems?: { orderId: string; orderItemId: string }[];
}

export const generateDocket = (
  bookingId: string,
  functionBookingId: string,
  createdAt: number,
  docketGenerationTime: number,
  orderItems: OrderItem[],
  docketId: string,
  orders: Orders,
  printerAreaIds: string[],
  booking: Booking | FunctionBooking = null,
  orderNumber: number = null,
  bucketId: string = null,
  batchSettingId: string = null,
  courseGroupsForDocket: CourseGroup[] = [],
  canUseMenuHeadingToGroup: boolean,
  operatorId: string,
  products: Products,
  customers: Customer[],
  sizes: ProductSizes,
  menuHeadings: MenuHeadings,
  printers: Printers,
  productGroups: ProductGroups,
  restaurantId: string,
  zoneId: string,
  getNewDocketItemId: () => string,
  orderId: string
) => {
  if (orderItems.length === 0) return null;

  const docketItems: DocketItems = {};

  const order = orders[orderId];
  const tableNumbers: { [orderId: string]: string } = {};
  forEach(orders, (order, orderId) => {
    if (order.orderType === OrderType.Pickup) tableNumbers[orderId] = String(order.orderNumber) || "";
    else tableNumbers[orderId] = order.tableNumber || "";
  });

  const consolidatedOrderItems: ConsolidatedOrderItem[] = consolidateOrderItems(orderItems, menuHeadings, tableNumbers);
  _.forEach(consolidatedOrderItems, (orderItem) => {
    const docketItemsId = getNewDocketItemId();
    const preparationsValue = getPreparationsData(orderItem?.preparations);
    const modifiersValue = getModifiersData(orderItem?.modifiers, sizes, products, false, orderItem.productId);
    const additionsValue = orderItem?.additions ? populateAdditionsNestedData(orderItem?.additions, sizes, products) : [];
    const comboValue = orderItem.comboGroup ? populateComboValue(orderItem.comboGroup, sizes, products, printers, productGroups, restaurantId, orderItem.productId, orderItem.sizeId) : [];
    const macAddresses = getProductMacAddresses(printers, productGroups, products[orderItem.productId], restaurantId);
    let courseGroupIndex = null;
    const orderItemHeadingId = orderItem.headingId;
    if (orderItem.courseGroupKey && (courseGroupsForDocket || []).length > 0) courseGroupIndex = _.findIndex(courseGroupsForDocket, (cg) => cg._key === orderItem.courseGroupKey);
    const customerNames = {};
    let tableNumber = "";
    if (orders[orderItem.orderId].customerId) {
      const customer = find(customers, (c) => c._key === orders[orderItem.orderId].customerId);
      if (customer) {
        if (customer.firstName) {
          customerNames[customer._key] = customer.firstName;
        }
        forEach(orderItem.consolidatedItems, ({ orderId }) => {
          if (orders[orderId].customerId) {
            const customer = find(customers, (c) => c._key === orders[orderId].customerId);
            if (customer?.firstName && !(customer._key in customerNames)) {
              customerNames[customer._key] = customer.firstName;
            }
          }
        });
        tableNumber = tableNumbers[orderItem.orderId] || "";
      }
    }

    docketItems[docketItemsId] = {
      name: products[orderItem.productId].docketName || products[orderItem.productId].name,
      size: sizes[orderItem.sizeId]?.name,
      hideSize: filter(products[orderItem.productId].sizes, (enabled) => enabled).length < 2 && !products[orderItem.productId].orderingRules?.showSingleSizeInDocket,
      heading: orderItemHeadingId && menuHeadings[orderItemHeadingId] ? menuHeadings[orderItemHeadingId].name : "",
      groupType: orderItemHeadingId && find(menuHeadings, (mh, menuHeadingId) => menuHeadingId === orderItemHeadingId) ? find(menuHeadings, (mh, menuHeadingId) => menuHeadingId === orderItemHeadingId).groupType : null,
      courseGroupIndex,
      courseGroupKey: orderItem.courseGroupKey || "",
      quantity: orderItem.quantity,
      preparations: preparationsValue,
      modifiers: modifiersValue,
      additions: additionsValue,
      combo: comboValue,
      orderId: orderItem.orderId,
      orderItemId: orderItem.orderItemId,
      printing: macAddresses,
      consolidatedItems: orderItem?.consolidatedItems || [],
      note: orderItem.note || null,
      customerNames,
      tableNumber,
    };
  });

  let courseGroups = [];
  if (courseGroupsForDocket && courseGroupsForDocket.length > 0) {
    courseGroups = courseGroupsForDocket.map((courseGroup) => courseGroup.name);
  }

  const bookingTableNo = order.orderType === OrderType.Pickup ? String(order.orderNumber) : order.bookingId || order.functionBookingId ? getTableNumbersOfBooking(booking).join(",") : null;
  let tableNumbersForDocket = "";
  if (order.orderType === OrderType.Pickup) {
    tableNumbersForDocket = chain(docketItems)
      .map((di) => tableNumbers[di.orderId])
      .filter((tn) => !!tn)
      .uniq()
      .join(",")
      .value();
  } else if (booking) {
    const batchTableGroups = booking.batchingTableGroups;
    tableNumbersForDocket = chain(docketItems)
      .map((di) => {
        const batchTableNumbers = find(batchTableGroups, (bt) => bt.split(",").includes(tableNumbers[di.orderId]));
        if (!batchTableNumbers) {
          if ("functionName" in booking) return tableNumbers[di.orderId] || "";
          else return tableNumbers[di.orderId] || bookingTableNo; //if no table number in order then use bookings table numbers
        }
        return batchTableNumbers.split(",");
      })
      .flatten()
      .filter((tn) => !!tn)
      .uniq()
      .join(",")
      .value();

    if (!tableNumbersForDocket && "functionName" in booking) tableNumbersForDocket = booking.functionName; /**@todo until we implement the mandatory selection of table number when sending order , use function name if no table number found*/
  }

  let customerNameOnDocket = "";
  let docketCustomerId = "";
  let pax = null;
  let bookingRef = "";
  if (booking) {
    if (booking.type !== BookingType.QROrderingOnly) {
      const customer = find(customers, (c) => c._key === booking.customerId);
      customerNameOnDocket = customer ? `${customer.firstName} ${customer.lastName}` : "";
      pax = Number(booking.pax) + (Number(booking.extraPax) || 0);
      bookingRef = order.bookingRef;
      docketCustomerId = order.customerId;
    }
  } else {
    const customer = find(customers, (c) => c._key === order.customerId);
    docketCustomerId = order.customerId;
    customerNameOnDocket = customer ? `${customer.firstName} ${customer.lastName}` : "";
  }
  const docket: Docket = {
    _key: docketId,
    customerName: customerNameOnDocket,
    date: Number(order.date) ? Number(order.date) : Number(moment(docketGenerationTime).tz(zoneId).format("YYYYMMDD")),
    time: moment(docketGenerationTime).tz(zoneId).format("hh:mmA"),
    tableNo: tableNumbersForDocket,
    bookingTableNo,
    printerAreaIds,
    orderType: order.orderType,
    pax,
    printStatus: DocketPrintStatus.NotPrinted,
    createdAt,
    docketGenerationTime,
    bucketId,
    batchSettingId,
    bookingId: functionBookingId ? "" : bookingId /**@todo there should be only either bookingId or functionBookingId in order   */,
    functionBookingId,
    bookingRef,
    restaurantId: order.restaurantId,
    docketItems,
    orderSource: order.orderSource,
    orderNumber: orderNumber || null,
    hidden: {
      one: false,
      prep: false,
      serve: false,
    },
    courseGroups,
    canUseMenuHeadingToGroup: canUseMenuHeadingToGroup === true,
    operatorId: operatorId ? operatorId : "",
    customerId: docketCustomerId,
    type: DocketType.Standard,
  };
  return docket;
};

export function populateAdditionsProductIds(additions, productIds) {
  _.forEach(additions, (additionProduct, additionGroupId) => {
    _.forEach(additionProduct, (addition, additionProdutId) => {
      productIds.push(additionProdutId);
      if (addition?.modifiers) Object.values(addition?.modifiers || []).forEach((modifier: OrderModifier) => productIds.push(modifier.productId));
      if (addition?.additions) populateAdditionsProductIds(addition?.additions, productIds);
    });
  });
}

function consolidateOrderItems(
  currentOrderItems: OrderItem[],
  menuHeadings: MenuHeadings,
  tableNumbers: {
    [orderId: string]: string;
  }
) {
  const addedDuplicates = [];
  const docketItems: ConsolidatedOrderItem[] = [];
  for (let j = 0; j < currentOrderItems.length; j++) {
    if (addedDuplicates.includes(currentOrderItems[j].orderItemId)) continue;
    const duplicates = currentOrderItems.filter((coi) => {
      const customerTableNumber1 = tableNumbers[coi.orderId] || "";
      const customerTableNumber2 = tableNumbers[currentOrderItems[j].orderId] || "";
      return (
        customerTableNumber1 === customerTableNumber2 &&
        coi.courseGroupKey === currentOrderItems[j].courseGroupKey &&
        coi.headingId === currentOrderItems[j].headingId &&
        coi.productId == currentOrderItems[j].productId &&
        coi.sizeId === currentOrderItems[j].sizeId &&
        // !coi.additions &&
        // !coi.modifiers &&
        // !coi.preparations &&
        // !currentOrderItems[j].additions &&
        // !currentOrderItems[j].modifiers &&
        // !currentOrderItems[j].preparations &&
        _.isEqual(coi.modifiers, currentOrderItems[j].modifiers) &&
        _.isEqual(coi.additions, currentOrderItems[j].additions) &&
        _.isEqual(coi.upsells, currentOrderItems[j].upsells) &&
        _.isEqual(coi.preparations, currentOrderItems[j].preparations) &&
        _.isEqual(coi.comboGroup, currentOrderItems[j].comboGroup) &&
        _.isEqual(coi.note, currentOrderItems[j].note)
      );
    });
    if (duplicates.length > 1) {
      addedDuplicates.push(...duplicates.map((d) => d.orderItemId));
      const totalQuantity = duplicates.reduce((sum, d) => sum + d.quantity, 0);
      docketItems.push({
        ...duplicates[0],
        quantity: totalQuantity,
        consolidatedItems: duplicates.map((du) => ({
          orderId: du.orderId,
          orderItemId: du.orderItemId,
          customerId: du.customerId,
        })),
      });
    } else docketItems.push(currentOrderItems[j]);
  }
  return _.orderBy(docketItems, (di) => (di.headingId && menuHeadings[di.headingId] ? menuHeadings[di.headingId].name : ""), "asc");
}

export function consolidateDocketItems(currentDocketItems: DocketItem[], isMultiTable): DocketItem[] {
  const addedDuplicates = [];
  const docketItems: DocketItem[] = [];
  for (let j = 0; j < currentDocketItems.length; j++) {
    if (addedDuplicates.includes(currentDocketItems[j].orderItemId)) continue;
    const duplicates = currentDocketItems.filter((coi: DocketItem) => {
      if (isMultiTable) {
        const customerTableNumber1 = coi.tableNumber;
        const customerTableNumber2 = currentDocketItems[j].tableNumber;
        if (customerTableNumber1 && customerTableNumber2 && customerTableNumber1 !== customerTableNumber2) return false;
      }
      return coi.courseGroupKey === currentDocketItems[j].courseGroupKey && coi.heading === currentDocketItems[j].heading && coi.name == currentDocketItems[j].name && coi.size === currentDocketItems[j].size && _.isEqual(coi.modifiers, currentDocketItems[j].modifiers) && _.isEqual(coi.additions, currentDocketItems[j].additions) && _.isEqual(coi.preparations, currentDocketItems[j].preparations) && _.isEqual(coi.combo, currentDocketItems[j].combo) && _.isEqual(coi.note, currentDocketItems[j].note);
    });
    if (duplicates.length > 1) {
      addedDuplicates.push(...duplicates.map((d) => d.orderItemId));
      const totalQuantity = duplicates.reduce((sum, d) => sum + d.quantity, 0);
      const customerNames = {};
      duplicates.forEach((duplicate, index) => {
        if (typeof duplicate.customerNames === "object") {
          for (const customerId in duplicate.customerNames) {
            customerNames[customerId] = duplicate.customerNames[customerId];
          }
        }
        if (Array.isArray(duplicate.customerNames)) {
          duplicate.customerNames.forEach((customerName) => (customerNames[index] = customerName));
        }
      });
      docketItems.push({
        ...duplicates[0],
        customerNames,
        quantity: totalQuantity,
        consolidatedItems: duplicates.map((du) => ({
          orderId: du.orderId,
          orderItemId: du.orderItemId,
        })),
      });
    } else docketItems.push(currentDocketItems[j]);
  }
  return docketItems;
}

function getModifiersData(modifiers: OrderModifiers, sizes: ProductSizes, products: Products, forAddition = false, productId: string) {
  return sortOrderModiferProducts(modifiers, products[productId]).map((modifier) => {
    const quantity = modifier?.quantity;
    const productName = products[modifier.productId].name;
    const size = sizes?.[modifier?.productSizeId] ? `${sizes?.[modifier.productSizeId].name}` : "";
    return { name: productName, quantity, size };
  });
}

function populateAdditionsNestedData(additions, sizes: ProductSizes, products: Products) {
  const additionsValue = [];
  _.forEach(additions, (additionProduct) => {
    _.forEach(additionProduct, (addition, additionProdutId) => {
      const newAddition: any = {};
      const additionData = getAdditionData({ ...addition, productId: additionProdutId }, sizes, products);
      newAddition.name = additionData.name;
      newAddition.quantity = additionData.quantity;
      if (addition?.modifiers) newAddition.modifiers = [...getModifiersData(addition.modifiers, sizes, products, false, additionProdutId)];
      if (addition?.preparations) newAddition.preparations = [...getPreparationsData(addition.preparations)];
      if (addition?.additions) newAddition.additions = [...populateAdditionsNestedData(addition?.additions, sizes, products)];
      additionsValue.push(newAddition);
    });
  });
  return additionsValue;
}

function populateComboValue(comboGroup: OrderItemProducts, sizes: ProductSizes, products: Products, printers: Printers, productGroups: ProductGroups, restaurantId: string, orderItemProductId: string, orderItemSizeId: string): DocketItemCombo[] {
  return sortComboGroupProducts(comboGroup, products[orderItemProductId].groupProducts[orderItemSizeId]).map((cg) => {
    const productId = cg.productId;
    const quantity = cg.quantity || null;
    const productName = products[productId].docketName || products[productId].name;
    const size = sizes?.[cg.sizeId] ? `${sizes?.[cg.sizeId].name}` : "";
    const preparationsValue = getPreparationsData(cg.preparations);
    const modifiersValue = getModifiersData(cg.modifiers, sizes, products, false, productId);
    const additionsValue = cg.additions ? populateAdditionsNestedData(cg.additions, sizes, products) : [];
    const macAddresses = getProductMacAddresses(printers, productGroups, products[productId], restaurantId);
    return {
      additions: additionsValue,
      preparations: preparationsValue,
      modifiers: modifiersValue,
      name: productName,
      size,
      quantity,
      printing: macAddresses,
    };
  });
}

function getAdditionData(addition, sizes: ProductSizes, products: Products) {
  const quantity = addition?.quantity || null;
  const productName = products[addition.productId].name;
  const size = sizes?.[addition?.sizeId] ? `${sizes?.[addition.sizeId].name}` : "";
  return { quantity, name: productName, size };
}

function getPreparationsData(preparations, forAddition = false) {
  const preparationsValue = [];
  Object.values(preparations || []).forEach((preparation: any[]) => {
    preparation.forEach((p) => {
      if (forAddition) preparationsValue.push({ name: p, quantity: "" });
      else preparationsValue.push(p);
    });
  });
  return preparationsValue;
}

function getProductMacAddresses(printers: Printers, productGroups, product: Product, restaurantId: string) {
  let docketSettings = product?.docketSettings?.[restaurantId] ? product.docketSettings[restaurantId] : {};
  const overrideGroupDocketSettings = product?.overrideGroupDocketSettings?.[restaurantId] ? product.overrideGroupDocketSettings[restaurantId] : false;
  let productGroup;
  let parentGroup = product.groupId;
  const activeMacAddresses = [];
  while (parentGroup != "-1" || Object.keys(docketSettings || {}).length > 0) {
    _.keys(docketSettings).forEach((printerId) => {
      if (docketSettings[printerId]) {
        const macAddress = printers[printerId]?.macAddress || "";
        if (macAddress && docketSettings[printerId] && !activeMacAddresses.find((ma) => ma.macAddress == macAddress))
          activeMacAddresses.push({
            macAddress,
            printStatus: DocketPrintStatus.NotPrinted,
          });
      }
    });
    if (overrideGroupDocketSettings) break;
    productGroup = parentGroup == "-1" ? {} : productGroups[parentGroup];
    parentGroup = productGroup?.parentGroup || "-1";
    docketSettings = productGroup?.docketSettings?.[restaurantId] || {};
  }
  return activeMacAddresses;
}

export function isDocketMultiTable(docket: OMSDisplayDocket | Docket, booking: Booking) {
  if (docket.orderType === OrderType.Pickup || (booking && docket.tableNo && booking?.alg?.number !== docket.tableNo)) {
    return false;
  }
  const tableNo = docket.tableNo;
  return tableNo ? tableNo.split(",").length > 1 : false;
}

export function sortDocketItems(docketItems: DocketItems): DocketItems {
  return Object.assign(
    {},
    ...Object.entries(docketItems)
      .sort(([k1, v1], [k2, v2]) => (v1.name > v2.name ? 1 : v2.name > v1.name ? -1 : 0))
      .map(([k, v]) => ({ [k]: v }))
  );
}

export const getAllBucketsFromTableGroupBuckets = (tableGroupBuckets): Bucket[] => {
  return chain(tableGroupBuckets)
    .map((buckets) => map(buckets, (bucket, bucketId) => ({ ...bucket, id: bucketId })))
    .flattenDeep()
    .value();
};
