import { SearchClient } from "algoliasearch/lite";
import { keys } from "lodash";
import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Restaurants } from "../../../resbutler-utils/types/Restaurant";
import { ProductSearchResult, ProductSize } from "../../../resbutler-utils/types/product";
import { ProductSearch } from "./ProductSearch";

export interface ProductSearchModalProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  limitToOne?: boolean;
  onCloseData?: (data: ProductSearchResult[]) => void;
  query?: string;
  initialValues?: ProductSearchResult[];
  productSizes?: ProductSize[];
  restaurantId?: string;
  searchClient?: SearchClient;
  tenantId: string;
  limitToOneSize?: boolean;
  clientId: string;
  restaurants?: Restaurants;
  publicStorageBucket: string;
}

export const ProductSearchModal: React.FC<ProductSearchModalProps> = ({ open, setOpen, limitToOne = false, onCloseData, query, initialValues = [], clientId, publicStorageBucket, productSizes, restaurantId, tenantId, searchClient, limitToOneSize, restaurants }) => {
  const [data, setData] = useState<ProductSearchResult[]>(initialValues);
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(restaurantId);

  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
      setData(initialValues);
    } else {
      document.body.style.overflow = "";
      setData([]);
    }
    return () => {
      document.body.style.overflow = "";
      setData([]);
    };
  }, [open]);

  const toggle = () => setOpen && setOpen(false);

  return (
    <Modal top isOpen={open} toggle={toggle} scrollable size="xl" className="search-for-products" zIndex={9999}>
      <ModalHeader toggle={toggle} tag={"div"}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="text-center">Search Products</div>
          {keys(restaurants || {}).length > 0 && (
            <div className="float-end">
              <select id="restaurant" className="form-select" value={selectedRestaurantId} onChange={(e) => setSelectedRestaurantId(e.target.value)}>
                {Object.keys(restaurants || {}).map((key) => (
                  <option key={key} value={key}>
                    {restaurants[key].name}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </ModalHeader>
      <ModalBody>
        <ProductSearch sizes={productSizes} data={data} query={query} limitToOne={limitToOne} clientId={clientId} limitToOneSize={limitToOneSize} restaurantId={selectedRestaurantId} onChange={(data) => setData(data)} searchClient={searchClient} tenantId={tenantId} publicStorageBucket={publicStorageBucket} />
      </ModalBody>
      <ModalFooter>
        <Button color="light" type="button" onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button
          color="success"
          type="button"
          onClick={() => {
            onCloseData(data);
            setOpen(false);
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
