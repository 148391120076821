import { catchExceptionCallback, getConfig } from '../core/utilities';
import firebase from 'firebase/compat/app';
import { useState } from 'react';

export const useUpdateDocket = () => {
  const [updatingDocket, setUpdatingDocket] = useState<boolean>(false);
  const updateDocket = async (docketId: string, values: any) => {
    try {
      setUpdatingDocket(true);
      const { client } = getConfig();
      await firebase.firestore().collection(`${client}/ordering/dockets`).doc(docketId).set(values, { merge: true });
    } catch (error) {
      catchExceptionCallback(error);
    } finally {
      setUpdatingDocket(false);
    }
  };

  return { updatingDocket, updateDocket };
};
