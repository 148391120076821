import { PaymentProviderResponseResult, PaymentProvidersType } from "./PaymentProvider";
import { PurchaseModel, RefundModel, SettlementModel } from "./purchase";

export interface Till {
  _key?: string;
  timeout: number;
  terminalId: string;
  suppressMerchantPassword?: boolean;
  restaurantId: string;
  printerId: string;
  name: string;
  enabled?: boolean;
  defineTipOn?: "till" | "eftpos";
  customerPickUp?: boolean;
  cashoutConfiguration?: {
    defineCashoutOn: "till" | "eftpos";
    enabled: boolean;
    cashoutOnly?: boolean;
  };
  cashDrawerOnCash?: boolean;
  integratedReceipt?: boolean;
  integratedSurcharge?: boolean;
  printerAreaIds: string[];
  isOfficeTill?: boolean;
  createdAt: number;
  cashTill?: boolean;
  cashDrawerCurrentCashCount?: CashDrawerCurrentCashCount;
}
export interface CashDrawerCurrentCashCount {
  coins: { label: string; value: number; cashCountUnits: number }[];
  notes: { label: string; value: number; cashCountUnits: number }[];
  operatorId: string;
}
export interface Tills {
  [tillId: string]: Till;
}

export interface PaymentProviderService extends PaymentService {
  ProviderClient: any;
  resbutlerApis: string;
  tillProcessingPaymentProviderCallback: (callbackType, data, saveTransaction: boolean) => void;
  clientId: string;
  type: PaymentProvidersType;
  dispatch: any;
  tillId: string;
  operatorId: string;
  restaurantId: string;
  currentUrl: string;
  currentPurchaseId: string;
  currentPaymentPayload: any;
  apiKey?: string;
  currentMerchantReceipt?: string;
  eventListener?: any;
  merchantId?: string;
  terminalId?: string;
  // purchase: (payload: PurchaseModel, url?: string) => void;
  // refund: (payload: RefundModel) => void;
  settlement: (payload: SettlementModel) => void;
  cancelTransaction: () => void;
  recoverLastTransaction: (payload, purchaseId) => void;
  manualPaymentConfirmation: (result: PaymentProviderResponseResult) => void;
  pair?: (values?: any) => void;
  motoCharge?: (payload: PurchaseModel) => void;
  cashoutOnly?: (payload: PurchaseModel) => void;
  unpair?: () => void;
  cancelPairing?: () => void;
  getAvailableTenant?: (countryCode: string) => any;
  fetchReportFromSettlement?: (zoneId: string, reportDates: string[], callback?: () => void) => void;
}
export interface TillSettlementReportDataTransaction {
  amount: string;
  cardType: string;
  merchantReference: string;
  settlementDate: string;
  transactionLocalDateTime: string;
  type: string;
  tyroReference: string;
  transactionTimestamp: number;
  tip?: string;
  cashout?: string;
  tillId?: string;
}

export interface TillSettlementReportData {
  mid: string;
  tid: string;
  transactions: TillSettlementReportDataTransaction[];
}

export interface TillSettlementData {
  _key?: string;
  tillId: string;
  operatorId: string;
  /**@deprecated */
  settlementTimestamp: number;
  report?: TillSettlementReportData;
  createdAt: number;
  updatedAt?: number;
  reportDate: number;
  restaurantId: string;
}

export enum TillSettlementTransactionType {
  Purchase = "purchase",
  Refund = "refund",
  Void = "void",
}
export enum ServiceConfigurationStatus {
  OPEN = 1,
  CLOSED = 2,
}
export interface ServiceConfigurations {
  [serviceConfigurationId: string]: ServiceConfiguration;
}

export interface ServiceConfiguration {
  tillId: string;
  mealId: string;
  date: string;
  restaurantId: string;
  status: ServiceConfigurationStatus;
  closedTimestamp?: number;
  cashCalculations?: ServiceCashCalculation;
  eftposCalculations?: ServiceEftposCalculation;
  createdAt: number;
  updatedAt: number;
  closedByOperator?: string;
}

export interface ServiceEftposCalculation {
  operatorId: string;
  operatorNote?: string;
  officeNote?: string;
  cards: EftposCalculation[];
}
export interface EftposCalculation {
  cardType: EftposCardTypeForTyro;
  amount: number;
}

export enum EftposCardTypeForTyro {
  EFTPOS = "EFTPOS",
  VISA = "Visa",
  MASTERCARD = "MasterCard",
  AMEX = "AMEX",
  JCB = "JCB",
  DINERS = "Diners",
}

export interface ServiceCashCalculation {
  coins: CashCalculation[];
  notes: CashCalculation[];
  operatorNote: string;
  officeNote?: string;
  operatorId: string;
}

export interface CashCalculation {
  label: string;
  value: number;
  startingFloatUnits: number;
  cashCountUnits: number;
  keptFloatUnits: number;
  netUnits: number;
}

export const cashTypeCoins = [
  { label: "5¢", value: 0.05 },
  { label: "10¢", value: 0.1 },
  { label: "20¢", value: 0.2 },
  { label: "50¢", value: 0.5 },
  { label: "1$", value: 1 },
  { label: "2$", value: 2 },
];

export const cashTypeNotes = [
  { label: "$5", value: 5 },
  { label: "$10", value: 10 },
  { label: "$20", value: 20 },
  { label: "$50", value: 50 },
  { label: "$100", value: 100 },
];

export interface CoinNoteValue {
  total: number;
  units: number;
}

export interface CoinItem {
  "coins_0.05"?: CoinNoteValue;
  "coins_0.1"?: CoinNoteValue;
  "coins_0.5"?: CoinNoteValue;
  coins_1?: CoinNoteValue;
  coins_2?: CoinNoteValue;
}

export interface NoteItem {
  notes_5?: CoinNoteValue;
  notes_10?: CoinNoteValue;
  notes_20?: CoinNoteValue;
  notes_50?: CoinNoteValue;
  notes_100?: CoinNoteValue;
}

export interface Coins {
  [tillId: string]: CoinItem;
}

export interface Notes {
  [tillId: string]: NoteItem;
}

export interface CashUpFloat {
  coins?: Coins[];
  notes?: Notes[];
}

export interface TillEventLog {
  _key?: string;
  sessionId: string;
  date: number;
  mealId: string;
  restaurantId: string;
  operatorId: string;
  tillId: string;
  events: { type: EVENTS; values: any }[];
  timeStamp: number;
}

export enum EVENTS {
  UNLOCK = 1,
  LOCK,
  PAIR_TERMINAL,
  ADD_PRODUCT,
  EDIT_PRODUCT,
  SAVE_ITEM_NOTE,
  PAY,
  SPLIT_PAY,
  CANCEL,
  APPLY_VOUCHER,
  REMOVE_VOUCHER,
  APPLY_GIFT_CERTIFICATE,
  MANUAL_CHARGE_ADD_TO_CART,
  ADD_ADJUSTMENTS,
  TILL_RESET,
  SEND_ORDERS_WITHOUT_PAYMENT,
  LINK_BOOKING,
  REMOVE_LINKED_BOOKING,
  LINK_FUNCTION_BOOKING,
  REMOVE_LINKED_FUNCTION_BOOKING,
  ADD_UNPAID_BILL_ITEMS_TO_CART,
  ADD_SPLIT_ITEMS_TO_CART,
  SAVE_CASH_CALCULATOR,
  CLOSE_MEAL_MERIOD,
  PAYMENT_PROVIDER_SETTLEMENT,
  PAYMENT_PROVIDER_SETTLEMENT_INQUIRY,
  EMAIL_INVOICE,
  SAVE_EFTPOS_CALCULATOR,
}

export interface PaymentService {
  purchase(payload: PurchaseModel, apiUrl?: string, purchaseId?: string): void;
  refund(payload: RefundModel, apiUrl?: string, purchaseId?: string): void;
}
