/**
 * imported from @types/google-libphonenumber instead of referencing it
 * so that other repos dont need to install google-libphonenumber unnecessarily
 */
export enum PhoneNumberType {
  FIXED_LINE = 0,
  MOBILE = 1,
  FIXED_LINE_OR_MOBILE = 2,
  TOLL_FREE = 3,
  PREMIUM_RATE = 4,
  SHARED_COST = 5,
  VOIP = 6,
  PERSONAL_NUMBER = 7,
  PAGER = 8,
  UAN = 9,
  VOICEMAIL = 10,
  UNKNOWN = -1,
}

export type ParsedPhone = {
  type: PhoneNumberType;
  countryCode: number; // ITU-T E.164 standard. like +61 for Aus
  isMobileNumber: boolean;
  isSupportedPhone: boolean; // is given phone number from supported countries
  isSupportedMobile: boolean; // if this phone is mobile and is from supported countries
  formatted?: string; // INTERNATIONAL format version of given phone number
};
