import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/database";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { catchExceptionCallback, getConfig } from "../core/utilities";
import { setRestaurants } from "../store/reducers/rootSlice";
import { StateType } from "../store/reducers/reducers";
import { getRestaurants } from "../resbutler-utils/utils/restaurantUtils";

function useRestaurants(firebaseLoading) {
  const { user } = useSelector((state: StateType) => ({
    user: state.root.user,
  }));
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    async function loadAsync() {
      try {
        const { tenantId } = getConfig();
        setLoading(true);
        const snap = await firebase.database().ref(`${tenantId}/restaurants`).orderByChild("enabled").equalTo(true).once("value");
        if (snap.exists()) {
          const restaurants = snap.val();
          dispatch(setRestaurants(getRestaurants(restaurants, user)));
        }
      } catch (error) {
        catchExceptionCallback(error);
      } finally {
        setLoading(false);
      }
    }

    if (!firebaseLoading && user) loadAsync();
  }, [firebaseLoading, user]);

  return [loading];
}

export default useRestaurants;
