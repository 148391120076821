import { Booking } from "./Booking";

export enum EventType {
  Save,
  StatusChange,
  OverrideAndSpill,
  Rerun,
  AddToWaitlist,
  RemoveOnHold,
  Update,
  CreatePayment,
  SwapBookings,
  Swap,
  Transfer,
  StandBy,
  Cancel,
  Message,
  OnHold,
  SMSNotifyStandBy,
  SaveQRBooking,
  SaveQR,
  SaveGuests,
  CloseTab,
  SaveQRGuest,
  Availability,
  AddTableBlock,
  RemoveTableBlock,
  AreaFlips,
  PaxLimits,
  SaveExclusive,
  BookingCashPayment,
  MealCashPayment,
  RestaurantCashPayment,
  UpdateSeatingLock,
}

export enum ProductEventActionType {
  RenameProduct,
  RenameProductGroup,
  ChangeProductGroup,
  AddProduct,
  DisableProduct,
  EnableProduct,
  AddProductGroup,
  EnableProductGroup,
  DisableProductGroup,
  AddProductInMenuHeading,
  DeleteProductInMenuHeading,
}

export type ProductLog = {
  action: ProductEventActionType;
  productGroupId: string;
  timestamp: number;
  productId?: string;
  read: boolean;
} & (DefaultMetadata | RenameProductOrGroupMetadata | ChangeProductGroupMetadata | ChangeProductInMenuHeadingMetaData);

type DefaultMetadata = {
  action: Exclude<ProductEventActionType, ProductEventActionType.RenameProduct | ProductEventActionType.RenameProductGroup | ProductEventActionType.ChangeProductGroup | ProductEventActionType.AddProductInMenuHeading | ProductEventActionType.DeleteProductInMenuHeading>;
  metadata: {
    newName: string;
  };
};

type RenameProductOrGroupMetadata = {
  action: ProductEventActionType.RenameProduct | ProductEventActionType.RenameProductGroup;
  metadata: {
    newName: string;
    oldName: string;
  };
};

type ChangeProductGroupMetadata = {
  action: ProductEventActionType.ChangeProductGroup;
  metadata: {
    newName: string;
    group1: string;
    group2: string;
  };
};

type ChangeProductInMenuHeadingMetaData = {
  action: ProductEventActionType.AddProductInMenuHeading | ProductEventActionType.DeleteProductInMenuHeading;
  metadata: {
    name: string;
    menuHeadingId: string;
    path: string;
    associatedData: any;
    menuHeading: any;
    newMenuHeading: any;
    message: string;
  };
};

export interface BookingLog extends EventLog {
  booking: Booking;
  bookingId: string;
  restaurantId: string;
  status: number;
  date: number;
}

export interface EventLog {
  type: number;
  eventTimestamp: {
    seconds: number;
    nanoseconds: number;
  };
  operatorId: string;
  operatorName?: string;
}
