/* eslint-disable no-unsafe-optional-chaining */
import { assign, keys, map, startsWith } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { Badge, Button, Spinner, Table } from 'reactstrap';
import { Menus } from '../../../../resbutler-utils/types/Menu';
import { Product, ProductSizes, StockLimits } from '../../../../resbutler-utils/types/product';
import AdditionForm from '../common/ProductForm';
import { useAppSelector } from './../../../../hooks/hook';
import { FilterProps } from './../../Filter';

interface AdditionalProps {
  menus: Menus;
  restaurantId: string;
  filteredProducts: Product[];
  loading?: boolean;
  filters?: FilterProps;
  productSizes?: ProductSizes;
}
function Additions({ menus, restaurantId, loading, filteredProducts, filters, productSizes }: AdditionalProps) {
  const values = useFormState().values as StockLimits;
  const [collapse, setCollapse] = useState({});
  const [additionsProducts, setAdditionsProducts] = useState<Product[]>([]);
  const [filterProducts, setFilterProducts] = useState<Product[]>([]);
  const { additionGroups, allProducts } = useAppSelector((state) => ({
    additionGroups: state.root.additionGroups,
    allProducts: state.root.allProducts,
  }));

  const getAdditionGroup = (additionGroupId: string) => additionGroups?.find((item) => item.id == additionGroupId);
  const getProduct = (productId: string) => allProducts?.find((item) => item.id == productId);

  useEffect(() => {
    let additionsProducts1 = [] as Product[];
    const productsWithAdditions = filteredProducts.filter((item) => item.additions?.length);
    for (let { additions = [] } of productsWithAdditions) {
      for (let { additionGroupId } of additions) {
        const additionGroup = getAdditionGroup(additionGroupId);
        if (additionGroup && additionGroup.products.length) {
          for (let { productId, sizes } of additionGroup.products) {
            const product = getProduct(productId);
            if (product && !additionsProducts1.find((item) => item.id == productId)) {
              const productObj = assign({}, product) as Product;
              productObj.sizes = sizes;
              additionsProducts1.push(productObj);
            }
          }
        }
      }
    }
    setAdditionsProducts(additionsProducts1);
  }, [filteredProducts, allProducts, additionGroups]);

  useEffect(() => {
    let result = [...additionsProducts];
    if (filters.query && filters.query.trim()?.length) {
      result = result.filter(({ name }: any) => startsWith(name.toLowerCase(), filters.query.trim().toLowerCase()));
    }
    setFilterProducts(result);
  }, [filters.query, additionsProducts]);

  // manage accordion for product
  function toggleCollapse(tab) {
    const tab_state = collapse?.[tab] ?? false;
    setCollapse({ ...collapse, [tab]: !tab_state });
  }

  return (
    <React.Fragment>
      <Table bordered className="sc-table">
        <tbody>
          {!loading ? (
            map(filterProducts, ({ id, sizes, name }: Product) => {
              return (
                <React.Fragment key={id}>
                  <tr>
                    <td>
                      <Button type="button" className="mr-2" onClick={() => toggleCollapse(id)}>
                        <i className={!collapse[id] ? 'fa fa-chevron-right' : 'fa fa-chevron-down'} aria-hidden="true" />
                      </Button>
                      {name}
                      <Badge color="light" className="ml-1">
                        {id in values?.upsells && Object.values(values.upsells[id]).some(({ isOverride = false }: any) => !!isOverride) ? 'override' : ''}
                      </Badge>
                    </td>
                  </tr>
                  <tr className={collapse[id] ? 'p-2 d-block' : 'd-none'}>
                    {map(keys(sizes || {}), (sizeId: string) => {
                      return sizes[sizeId] && productSizes?.[sizeId] ? <AdditionForm stockLimitKey="additions" productId={id} menus={menus} size={productSizes[sizeId]} key={id + sizeId} restaurantId={restaurantId} /> : null;
                    })}
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <Spinner size="sm" className="mr-2" >{""}</Spinner>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default Additions;
