/**
 * @file responsible for managing consistent static values for MenuItemPopup
 */
export default {
  status: {
    SOLD: "sold",
    HIDE: "hide",
    APPLIED: "applied",
  },
  appliedTo: {
    TOTAL: "total",
    MENUS: "menus",
    DINE_IN_TAKEAWAY: "dineIn_takeaways",
  },
  outOfStockLabel: {
    SOLD_OUT: "sold_out",
    hide: "hide",
  },
};
