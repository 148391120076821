export interface Statuses {
  confirmed: {
    text: string;
    value: number;
  };
  arrived: {
    text: string;
    value: number;
  };
  seated: {
    text: string;
    value: number;
  };
  vacated: {
    text: string;
    value: number;
  };
  cancelled: {
    text: string;
    value: number;
  };
  noShow: {
    text: string;
    value: number;
  };
}

// export interface FunctionStatus {
//   inProgress: {
//     text: 'In Progress';
//     value: 1;
//     category: 1;
//   };
//   depositRequested: {
//     text: 'Deposit Requested';
//     value: 2;
//     category: 1;
//   };
//   onHold: {
//     text: 'On Hold';
//     vaule: 3;
//     category: 2;
//   };
//   confirmed: {
//     text: 'Confirmed';
//     value: 4;
//     category: 2;
//   };
//   vacated: {
//     text: 'Vacated';
//     value: 5;
//     category: 2;
//   };
//   lost: {
//     text: 'Lost';
//     value: 6;
//     category: 3;
//   };
//   denied: {
//     text: 'Denied';
//     value: 7;
//     category: 3;
//   };
//   discardedDate: {
//     text: 'Discarded Date';
//     value: 8;
//     category: 3;
//   };
//   cancelled: {
//     text: 'Cancelled';
//     value: 9;
//     category: 3;
//   };
//   arrived: {
//     text: 'Arrived';
//     value: 10;
//     category: 2;
//   };
//   seated: {
//     text: 'Seated';
//     value: 11;
//     category: 2;
//   };
// }

export enum FunctionCategory {
  Enquiry = 1,
  Confirmed,
  Cancelled,
  Postponed,
}
export interface FunctionStatus {
  text: string;
  value: number;
  category: FunctionCategory;
}
export interface FunctionStatuses {
  inProgress: FunctionStatus;
  depositRequested: FunctionStatus;
  onHold: FunctionStatus;
  confirmed: FunctionStatus;
  vacated: FunctionStatus;
  lost: FunctionStatus;
  denied: FunctionStatus;
  discardedDate: FunctionStatus;
  cancelled: FunctionStatus;
  seated: FunctionStatus;
  arrived: FunctionStatus;
  postponed: FunctionStatus;
}
