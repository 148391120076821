export enum FunctionProductCategory {
  FOOD_ADULT = 1,
  FOOD_CHILDREN,
  FOOD_ADDITIONAL,
  BEVERAGE_ADULT,
  BEVERAGE_CHILDREN,
  BEVERAGE_ADDITIONAL,
  BEVERAGE_BAR_TAB,
  BEVERAGE_CONSUMPTION,
  COMBINATION_ADULT,
  COMBINATION_CHILDREN,
  COMBINATION_FOOD_ADDITION,
  COMBINATION_BEVERAGE_ADDITION,
  COMBINATION_BAR_TAB,
  COMBINATION_BEVERAGE_CONSUMPTION,
  ADDITIONAL,
  ALL,
}
