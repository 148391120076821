import 'firebase/compat/auth';
import 'firebase/compat/database';
import moment from 'moment';

function useUrlParams() {
  let [date, restaurantId] = window.location.hash ? window.location.hash.slice(window.location.hash.indexOf('#') + 1).split('/') : [];
  if (!date) date = moment().format('YYYYMMDD');

  return [restaurantId, date] as [string, string];
}

export default useUrlParams;
