import { AnyAction, configureStore, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import middlewares from './middlewares/middlewares';
import { persistedState } from './persisted.store';
import reducers from './reducers/reducers';

const loggerMiddleware = createLogger();
const middleware = [...middlewares, thunk];
// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'development') middleware.push(loggerMiddleware);
const store = configureStore({
  reducer: reducers,
  preloadedState: persistedState,
  middleware,
});

export type AppDispatch = typeof store.dispatch;
export type ReduxState = ReturnType<typeof reducers>;
export type TypedDispatch = ThunkDispatch<ReduxState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, ReduxState, unknown, AnyAction>;
export const reduxStore = store;
