import _, { keys } from 'lodash';
import moment from 'moment-timezone';
import TreeSelect, { SHOW_PARENT } from 'rc-tree-select';
import React, { useCallback, useEffect } from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import MultiToggle from 'react-multi-toggle-extra';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { Button, ButtonGroup, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { useFilteredDockets } from '../../hooks/useFilteredDockets';
import useUrlParams from '../../hooks/useUrlParams';
import MenuItemsPopUp from '../../oms/MenuItemPopup';
import { CHANGE_SETTING } from '../../store/actions/settings.actions';
import { TOGGLE_SUMMARY } from '../../store/actions/themes.actions';
import { StateType } from '../../store/reducers/reducers';
import { getOperationModeSelector, setDisplay, setFilterPrinterIds, setOrderMode, setProgress } from '../../store/reducers/rootSlice';
import { getToggleSummarySelector } from '../../store/reducers/themes.reducers';
import ToggleOptions from '../Common/ToggleOptions';
import { ON_CLICK_DEBOUNCE_WAIT } from './../../constants';
import { OperationMode } from './Offsidebar';

export enum DisplayType {
  PrepDisplay = 0,
  ServeDisplay = 1,
}

export enum ProgressType {
  Active = 0,
  Completed = 1,
  All = 2,
  Removed = 3,
}

export enum OrderModeType {
  PreOrder = 0,
  SentOrder = 1,
  CurrentBasketOrder = 2,
}

const OmsSelector = React.memo<{ hiddenKey: string }>(function OmsSelector({ hiddenKey }) {
  const [restaurantId, date] = useUrlParams() as any;

  const history = useHistory();
  const dispatch = useDispatch();
  const { restaurants, orderMode, operationMode, printers, filterPrinterIds, display, progress, showSummary } = useSelector(
    (state: StateType) => ({
      restaurants: state.root.restaurants,
      orderMode: state.root.orderMode,
      operationMode: getOperationModeSelector(state as any),
      printers: state.root.printers,
      filterPrinterIds: state.root.filterPrinterIds,
      display: state.root.display,
      progress: state.root.progress,
      showSummary: getToggleSummarySelector(state),
    }),
    shallowEqual
  );

  const { completedDocketNumber, removedDocketNumber, activeDockets, allCount } = useFilteredDockets(hiddenKey);

  const printerTreeData = keys(printers).map((key) => ({
    key,
    title: printers[key].name,
    value: key,
  }));

  useEffect(() => {
    const printerIds = keys(printers);
    dispatch(setFilterPrinterIds(printerIds));
  }, [printers]);

  const getTodayBtnStyle = (date: any, currentDate: any) => {
    let style: any = { borderColor: '#27c24c ', height: '35px', width: '100%' };
    if (date === currentDate) {
      style.backgroundColor = '#27c24c';
      style.color = 'white';
    } else {
      style.backgroundColor = '#fff';
      style.color = '#27c24c';
    }
    return style;
  };

  const renderTodayDateTime = () => {
    const currentDate = moment().tz(restaurants[restaurantId]?.zoneId)?.format('YYYYMMDD');
    return (
      <Button style={getTodayBtnStyle(date, currentDate)} onClick={() => history.push(`${window.location.pathname}#${currentDate}/${restaurantId}`)}>
        Today
      </Button>
    );
  };

  // const renderModeToggle = () => {
  //   let options = [
  //     {
  //       displayName: 'Standard',
  //       value: 0,
  //       optionClass: 'standard-toggle',
  //     },
  //     {
  //       displayName: 'Functions',
  //       value: 1,
  //       optionClass: 'functions-toggle',
  //     },
  //     {
  //       displayName: 'Community',
  //       value: 2,
  //       optionClass: 'sharedSpace-toggle',
  //     },
  //   ];

  //   return (
  //     <MultiToggle
  //       className="maestro-toggle-buttons"
  //       style={{ height: '35px', width: '100%' }}
  //       options={options}
  //       selectedOption={maestroMode}
  //       active={!maestroMode}
  //       onSelectOption={(value) => {
  //         dispatch({ type: MAESTRO_MODE, maestroMode: value });
  //       }}
  //     />
  //   );
  // };
  const renderOrderModeToggle = () => {
    let options = [
      {
        displayName: 'Pre Orders',
        value: OrderModeType.PreOrder,
      },
      {
        displayName: 'Basket',
        value: OrderModeType.CurrentBasketOrder,
      },
      {
        displayName: 'Sent Orders',
        value: OrderModeType.SentOrder,
      },
    ];

    return <MultiToggle className="oms-toggle-buttons" options={options} selectedOption={orderMode} onSelectOption={(value) => dispatch(setOrderMode(value))} />;
  };

  const renderProgress = () => (
    <UncontrolledDropdown className="btn-group" style={{ height: '35px', width: '100%' }}>
      <DropdownToggle caret>
        <span className="font-weight-bold ">
          {progress === ProgressType.All && <>All ({allCount})</>}
          {progress === ProgressType.Active && <>Active ({activeDockets})</>}
          {progress === ProgressType.Completed && <>Completed ({completedDocketNumber})</>}
          {progress === ProgressType.Removed && <>Removed ({removedDocketNumber})</>}
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          value={ProgressType.All}
          onClick={() => {
            dispatch(setProgress(ProgressType.All));
          }}
        >
          All ({allCount})
        </DropdownItem>
        <DropdownItem
          value={ProgressType.Active}
          onClick={() => {
            dispatch(setProgress(ProgressType.Active));
          }}
        >
          Active ({activeDockets})
        </DropdownItem>
        <DropdownItem
          value={ProgressType.Completed}
          onClick={() => {
            dispatch(setProgress(ProgressType.Completed));
            dispatch({ type: CHANGE_SETTING, name: 'showHiddenDockets', value: false });
          }}
        >
          Completed ({completedDocketNumber})
        </DropdownItem>
        <DropdownItem
          value={ProgressType.Removed}
          onClick={() => {
            dispatch(setProgress(ProgressType.Removed));
            dispatch({ type: CHANGE_SETTING, name: 'showHiddenDockets', value: false });
          }}
        >
          Removed ({removedDocketNumber})
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderDisplay = () => (
    <UncontrolledDropdown className="btn-group" style={{ height: '35px', width: '100%' }}>
      <DropdownToggle caret>
        <span className="text">{display === DisplayType.PrepDisplay ? <b>Prep Display</b> : <b>Serve Display</b>}</span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem
          value={DisplayType.PrepDisplay}
          onClick={() => {
            dispatch(setDisplay(DisplayType.PrepDisplay));
          }}
        >
          Prep Display
        </DropdownItem>
        <DropdownItem
          value={DisplayType.ServeDisplay}
          onClick={() => {
            dispatch(setDisplay(DisplayType.ServeDisplay));
          }}
        >
          Serve Display
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );

  const renderDateTime = () => {
    return (
      <ButtonGroup className="maestro-date" style={{ height: '35px', width: '100%' }}>
        <Button onClick={_.debounce(() => history.push(`${window.location.pathname}#${moment(date, 'YYYYMMDD').add(-1, 'days').format('YYYYMMDD')}/${restaurantId}`), ON_CLICK_DEBOUNCE_WAIT)}>
          <i className="fas fa-chevron-left"></i>
        </Button>
        <Datetime className=" maestro-selector-bold " dateFormat="ddd DD MMMM YYYY" closeOnSelect onChange={(e) => history.push(`${window.location.pathname}#${moment(e).format('YYYYMMDD')}/${restaurantId}`)} timeFormat={false} value={moment(date, 'YYYYMMDD')} />
        <Button onClick={_.debounce(() => history.push(`${window.location.pathname}#${moment(date, 'YYYYMMDD').add(1, 'days').format('YYYYMMDD')}/${restaurantId}`), ON_CLICK_DEBOUNCE_WAIT)}>
          <i className="fas fa-chevron-right"></i>
        </Button>
      </ButtonGroup>
    );
  };

  const renderPrinters = () => {
    return (
      <TreeSelect
        className="form-control tree-select"
        choiceTransitionName="rc-tree-select-selection__choice-zoom"
        dropdownStyle={{ height: 100, overflow: 'auto' }}
        dropdownPopupAlign={{ overflow: { adjustY: 0, adjustX: 0 }, offset: [0, 2] }}
        treeLine
        value={filterPrinterIds}
        placeholder={'Select Printers'}
        showIcon={false}
        maxTagTextLength={25}
        autoClearSearchValue
        treeData={printerTreeData}
        treeCheckable
        showCheckedStrategy={SHOW_PARENT}
        maxTagCount={20}
        onChange={(value) => {
          dispatch(setFilterPrinterIds(value));
        }}
        maxTagPlaceholder={(valueList) => {
          return `+${valueList.length}`;
        }}
      />
    );
  };

  const toggle = useCallback(() => {
    dispatch({ type: TOGGLE_SUMMARY, showSummary: !showSummary });
  }, [showSummary]);

  const renderToggleSummary = () => {
    return <ToggleOptions onClick={toggle} on={'Summary'} off={'Summary'} active={showSummary} width={'100%'} />;
  };

  return (
    <>
      <Card className="oms-selector card-default" style={{ marginTop: '5px', backgroundColor: '#555E65', padding: '5px' }}>
        <CardBody style={{ padding: '0px' }}>
          <div className="grid-container">
            <div className="grid-item">
              <div className="btn-group d-flex">
                <div>{renderDateTime()}</div>
                <div className="flex-grow-1 ml-1">{renderTodayDateTime()}</div>
              </div>
            </div>
            <div className="grid-item">
              <MenuItemsPopUp restaurantId={restaurantId} date={date} />
            </div>
            <div className="grid-item">{renderOrderModeToggle()}</div>
            <div className="grid-item">
              <div className="btn-group d-flex">
                <div className="w-50">{orderMode === OrderModeType.SentOrder && operationMode === OperationMode.AllStep ? renderDisplay() : null}</div>
                <div className="w-50 ml-1">{orderMode === OrderModeType.SentOrder ? renderProgress() : null}</div>
              </div>
            </div>
            <div className="grid-item">
              <div className="btn-group w-100">
                <div className="w-50">{renderPrinters()}</div>
                <div className="w-50 ml-1 ">{renderToggleSummary()}</div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
});

export default withRouter(OmsSelector);
