import { Restaurants } from "../types/Restaurant";
import { WizUser } from "../types/WizUser";

export function getRestaurants(restaurants: Restaurants, user: WizUser) {
  const restaurants1: Restaurants = {};
  for (const restaurantId in restaurants) {
    if (restaurants[restaurantId].enabled === true && user && user.restaurants && user.restaurants[restaurantId]) {
      restaurants1[restaurantId] = restaurants[restaurantId];
    }
  }
  return restaurants1;
}
