import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { OperationMode } from '../components/Layout/Offsidebar';
import { DisplayType } from '../components/Layout/OmsSelector';
import { isDocketCompleted } from '../helpers/dockets';
import { OMSDisplayDocket, OMSDocketGeneratedFrom } from '../resbutler-utils/types/Docket';
import { StateType } from '../store/reducers/reducers';
import { getOperationModeSelector } from '../store/reducers/rootSlice';
import { useUpdateDocket } from './useUpdateDocket';

export const useHiddenDockets = () => {
  const { display, operationMode, dockets } = useSelector((state: StateType) => ({
    display: state.root.display,
    operationMode: getOperationModeSelector(state),
    dockets: state.root.dockets,
  }));

  const { updateDocket } = useUpdateDocket();

  // which key to apply the hidden status based on modes
  const hiddenKey = operationMode === OperationMode.OneStep ? 'one' : display === DisplayType.PrepDisplay ? 'prep' : 'serve';

  // number of hidden dockets to show in button badge
  const hiddenDocketCount = dockets.filter((docket) => {
    if (docket.docketGeneratedFrom === OMSDocketGeneratedFrom.Docket) {
      return docket.hidden[hiddenKey];
    }
    return false;
  }).length;

  // red for hidden and white for not
  const hiddenDocketStyle = useCallback((docket: OMSDisplayDocket) => ({ color: docket.hidden[hiddenKey] ? '#f05050' : '#fff' }), []);

  // update hidden status of docket based on mode
  const updateDocketHidden = useCallback(
    (docket: OMSDisplayDocket) =>
      updateDocket(docket._key, {
        hidden: { ...docket.hidden, ...{ [hiddenKey]: !docket.hidden[hiddenKey] } },
      }),
    [operationMode]
  );

  // reset hidden status when docket becomes completed
  const resetHiddenStatusOnComplete = useCallback((docket: OMSDisplayDocket) => {
    let key;
    if (operationMode === OperationMode.OneStep) {
      key = 'one';
    } else {
      if (display === DisplayType.PrepDisplay) {
        key = 'prep';
      } else if (display === DisplayType.ServeDisplay) {
        key = 'serve';
      }
    }
    if (isDocketCompleted(docket, operationMode, display)) {
      updateDocket(docket._key, {
        hidden: { ...docket.hidden, ...{ [key]: false } },
      });
    }
  }, []);

  return {
    hiddenKey,
    hiddenDocketCount,
    hiddenDocketStyle,
    updateDocketHidden,
    resetHiddenStatusOnComplete,
  };
};
