import classNames from 'classnames';
import { useEffect, useState } from 'react';
import screenfull from 'screenfull';

const ToggleFullscreen = () => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  useEffect(() => {
    screenfull.on('change', () => {
      setIsFullScreen(screenfull.isFullscreen ? true : false);
    });
  }, []);
  return (
    <a
      className="nav-link"
      onClick={() => {
        screenfull.toggle();
      }}
    >
      <i className={classNames('text-white ', { 'fa fa-compress': isFullScreen, 'fa fa-expand': !isFullScreen })} />
    </a>
  );
};

export default ToggleFullscreen;
