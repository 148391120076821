import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es6/set';
import 'core-js/es6/string';
import 'core-js/es7/array';
import 'core-js/es7/object';
import 'raf/polyfill';

import { render } from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import { reduxStore } from './store/store';

render(
  <Provider store={reduxStore}>
    <App />
  </Provider>,
  document.getElementById('app') as HTMLElement
);
