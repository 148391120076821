import { lazy, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

const Login = lazy(() => import('./components/Pages/Login'));
const Challenge = lazy(() => import('./components/Pages/Challenge'));
const OMS = lazy(() => import('./oms/index'));

/* Used to render a lazy component with react-router */
const waitFor = (Tag) => (props) => <Tag {...props} />;

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
  '/login',
  '/challenge',
  /* See full project for reference */
];

const Routes = ({ location }) => {
  if (listofPages.indexOf(location.pathname) > -1) {
    return (
      // Page Layout component wrapper
      <BasePage>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route path="/login" component={waitFor(Login)} />
            <Route path="/challenge" component={waitFor(Challenge)} />
          </Switch>
        </Suspense>
      </BasePage>
    );
  } else {
    return (
      // Layout component wrapper
      // Use <BaseHorizontal> to change layout
      <Base>
        <Suspense fallback={<PageLoader />}>
          <Switch location={location}>
            <Route exact path={`/dockets`} component={waitFor(OMS)} />
            <Route exact path={`/`} component={waitFor(OMS)} />
          </Switch>
        </Suspense>
      </Base>
    );
  }
};

export default withRouter(Routes);
