import React from "react";
import _ from "lodash";
import { SearchClient } from "algoliasearch/lite";
import { HierarchicalMenu, InstantSearch, connectSearchBox, connectHits, Pagination } from "react-instantsearch-dom";

export interface DynamicAlgoliaSearchProps {
  searchClient: SearchClient;
  indexName?: string;
  renderSearchInput: (params: any) => React.ReactNode;
  renderHits: (params: any) => React.ReactNode;
  children: (params: any) => React.ReactNode;
}

const SearchBox: any = connectSearchBox(({ renderInput, ...rest }: any) => (
  <React.Fragment>
    {renderInput({
      ...rest,
    })}
  </React.Fragment>
));

const Hits: any = connectHits(({ renderHits, ...rest }: any) => (
  <React.Fragment>
    {renderHits({
      ...rest,
    })}
  </React.Fragment>
));

export const DynamicAlgoliaSearch = ({
  searchClient,
  indexName,
  children,

  // render props
  renderSearchInput,
  renderHits,
}: DynamicAlgoliaSearchProps) => {
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient}>
      {children({
        renderSearchBox: () => <SearchBox renderInput={renderSearchInput} />,
        renderSearchMenu: () => <HierarchicalMenu attributes={["groups.lvl0", "groups.lvl1", "groups.lvl2", "groups.lvl3"]} />,
        renderSearchHits: () => <Hits renderHits={renderHits} />,
        renderSearchPagination: () => <Pagination />,
      })}
    </InstantSearch>
  );
};
