import { BookingBase } from "./Booking";
import { Customer } from "./customer";
import { PackagePriceType, Packages } from "./Menu";
import { FunctionPaymentSummary } from "./transaction";

export interface RecordedPayment {
  amountPaid: number;
  authorisedBy: string;
  date: string;
  note?: string;
}

export interface FunctionAdditionalItem {
  startTime?: string;
  endTime?: string;
  title?: string;
  price?: number;
  label?: string;
  short?: string;
  qty?: number;
  value: string;
  productSizeId?: string;
  duration?: number;
  pricingProductId?: string;
  groupHeadings?: FunctionBookingMenuItems;
  priceType?: PackagePriceType;
}

export enum FunctionPackageSelectionType {
  Separate = "separate",
  Combined = "combined",
}

export interface FunctionBookingMenuItemProducts {
  [productId: string]: {
    [productSizeId: string]: {
      isEnabled: boolean;
      valuePrice?: number;
      valueQty?: number;
      productOrder: number;
      productSizeOrder: number;
    };
  };
}

export interface FunctionBookingMenuItems {
  [groupHeadingId: string]: FunctionBookingMenuItem;
}
export interface FunctionBookingMenuItem {
  _key: string;
  serveStartTime?: any;
  specialMenuItems?: any;
  valueQty?: number;
  order?: number;
  products?: FunctionBookingMenuItemProducts;
  menuItems?: {
    [groupHeadingId: string]: FunctionBookingMenuItem;
  };
}
export interface Timeline {
  startTime: number;
  endTime?: number;
  notesCategory?: {
    label: string;
    value: string;
  };
  notes?: string[];
}

export interface FunctionOnlineOrderingOption {
  food?: {
    additional?: boolean;
    adult?: boolean;
    child?: boolean;
  };
  beverage?: {
    additional?: boolean;
    adult?: boolean;
    child?: boolean;
    barTab?: boolean;
    onConsumption?: boolean;
  };
}

// Base interface for common victuals (food / beverage) properties
export interface VictualsBase {
  adult?: FunctionBookingMenuItems;
  adultMenu?: string;
  adultMenuPricingId?: string;
  adultMenuSelectionId?: string;
  adultNotes?: string;

  isGuestSpecialMeals?: boolean;
  specialMealsCount?: string;
  isOverridePackagePax?: boolean;
  overridePaxCount?: number;
  
  child?: FunctionBookingMenuItems;
  childMenu?: string;
  childMenuPricingId?: string;
  childMenuSelectionId?: string;
  childNotes?: string;
  isChildGuestSpecialMeals?: boolean;
  childSpecialMealsCount?: string;
  isChildOverridePackagePax?: boolean;
  childOverridePaxCount?: number;
  addNotes?: string;
}

export interface FunctionFood extends VictualsBase {
  addFood?: FunctionAdditionalItem[];
  notes?: string;
}

export interface FunctionBeverage extends VictualsBase {
  addBeverage?: FunctionAdditionalItem[];
  addBeverageNotes?: string;
  
  isBarTabEnable?: boolean;
  barTabAmount?: string;
  barTabMenu?: string;
  barTab?: FunctionBookingMenuItems;  // selected items from barTabMenu
  barTabStartTime?: string;
  barTabDuration?: string;
  barNotes?: string;
  
  isBeverageConsumptionTabEnable?: boolean;
  beverageConsumptionMenu?: string;
  beverageConsumption?: FunctionBookingMenuItems;  // selected items from beverageConsumptionMenu
  beverageConsumptionStartTime?: string;
  beverageConsumptionDuration?: string;
  beverageConsumptionNotes?: string;
}

export interface FunctionFoodAndBeverage extends FunctionFood, FunctionBeverage {
  isFoodAndBeverageConsumptionTabEnable?: boolean;
  /** @deprecated use addNotes instead */
  addFoodNotes?: string;
}

export interface FunctionBooking extends BookingBase {
  intervalId: number; // mandatory for Function
  customerId: string; // mandatory for Function
  groupAreaId: string; // mandatory for Function
  functionName: string;
  setupTime?: number;
  resetTime?: number;
  minimumSpend?: number;
  surcharge?: number;
  serviceCharge?: number;
  overrideMinimumSpend?: boolean;
  budgetFrom?: number;
  budgetTo?: number;
  referral?: string;
  termsAndCondtions?: { content: string; overrideDefault: boolean };
  nonExclusive?: boolean;
  functionEventType: "corporate" | "personal";
  packageSelection: FunctionPackageSelectionType;
  tableSeatLayoutId?: string;
  functionAreaCombinationId?: string;
  functionType?: string;
  areaGroups: [];
  serviceStyle?: string;
  billingContactType: "primary" | "added" | "alternative";
  billingContact?: Customer;
  billingContactId?: string;
  timeline: Timeline[];
  notes?: string;
  needParking?: boolean;
  needWristbands?: boolean;
  needAccommodation?: boolean;
  accommodationNote?: string;
  parkingNote?: string;
  dietaryNote?: string;
  operatorId?: string;
  wristbandsColor?: string;
  discount?: number;
  total?: number;
  balance?: number;
  food?: FunctionFood;
  beverage?: FunctionBeverage;
  foodAndBeverage?: FunctionFoodAndBeverage;
  foodCustomPackages?: Packages;
  addFoodCustomPackages: Packages;
  beverageCustomPackages?: Packages;
  addBeverageCustomPackages?: Packages;
  foodAndBeverageCustomPackages?: Packages;
  addFoodInFoodBeverageCustomPackages?: Packages;
  addBeverageInFoodBeverageCustomPackages?: Packages;
  beverageConsumptionCustomPackages?: Packages;
  barTabCustomPackages?: Packages;
  additionalItems?: FunctionAdditionalItem[];
  paymentSchedules?: PaymentSchedule[];
  source: FunctionSource;
  paymentCompleted?: boolean;
  additionalItemsNotes?: string;
  paymentSummary: FunctionPaymentSummary;
  functionStatementEmailSent?: boolean;
  onlineOrderingOption?: FunctionOnlineOrderingOption;
}

// export enum PaymentRequirementTypes {
//   CreditCardDetails = 0,
//   Deposit = 1,
//   FullPayment = 2,
// }

export interface ConfirmedFunctions {
  statuses: [3, 4];
}

export interface EnquiryFunctions {
  statuses: [0, 1, 2];
}
export interface FunctionType {
  status: ConfirmedFunctions | EnquiryFunctions;
  label: "Confirmed Functions" | "Functions Enquiries";
  groupName: "Function Confirmed" | "Function Enquiry";
  backgroundColor: "#f875cc" | "#eabbda";
}

export interface FunctionBookings {
  [functionId: string]: FunctionBooking;
}

export enum FunctionSource {
  bookingForm = 1,
  widget = 2,
}

export interface FunctionMinimumSpendDetail {
  depositAmount?: string;
  depositType: "%" | "$";
  from: string;
  to: string;
  isByTime?: boolean;
  isClosed?: boolean;
  isPriceApplication?: boolean;
  minimumSpendAmount?: string;
  minimumSpend?: {
    from: string;
    to: string;
  }[];
  minimumSpendTime?: {
    durationHour: {
      fromDuration: string;
      toDuration: string;
      minimumSpendDuration: string;
    }[];
    byDuration?: boolean;
  }[];
}

export interface FunctionMinimumSpendDetails {
  [day: string]: FunctionMinimumSpendDetail;
}

export interface FunctionStylePayments {
  [day: string]: FunctionMinimumSpendDetails;
}

export interface FunctionPayment {
  description: string;
  name: string;
  endDate: string;
  isServiceStyleEnabled: boolean;
  startDate: string;
  stylePaymentsByServiceStyleId: {
    [serviceStyleId: string]: FunctionMinimumSpendDetails;
  };
  stylePaymentsDefault: FunctionMinimumSpendDetails;
  enabled?: boolean;
}

export interface FunctionAreaCombination {
  _key?: string;
  areaCombinationType: string;
  areaVariations?: {
    [tableLayoutId: string]: {
      areas: {
        [areaId: string]: {
          areaVariationId: string;
        };
      };
    };
  };
  areas: string[];
  description: string;
  enabled: boolean;
  name: string;
  restaurantId: string;
  payments?: FunctionPayment[];
  serviceStyles: string[];
  tableLayouts: {
    [serviceStyleId: string]: {
      [tableSeatLayoutId: string]: {
        minPax: number;
        maxPax: number;
      };
    };
  };
  tableSeatLayouts: {
    [serviceStyleId: string]: string[];
  };
}

export interface FunctionAreaCombinations {
  [functionAreaCombinationId: string]: FunctionAreaCombination;
}

export interface FunctionServiceStyle {
  name: string;
  enabled: boolean;
}

export interface FunctionServiceStyles {
  [functionServiceStyleId: string]: FunctionServiceStyle;
}

export interface FunctionTableSeatLayout {
  name: string;
  enabled: boolean;
  restaurantId: string;
  serviceStyles: {
    [functionServiceStyleId: string]: boolean;
  };
}
export interface FunctionTableSeatLayouts {
  [functionTableSeatLayoutId: string]: FunctionTableSeatLayout;
}

export interface PaymentSchedule {
  _key: string;
  amount: number;
  description: string;
  dueDate: string;
  order?: number;
  reminderKey: string;
  isVoid?: boolean;
  creditNote?: CreditNote;
}

export interface FunctionPaymentInvoices {
  [id: string]: FunctionPaymentInvoice;
}
export interface FunctionPaymentInvoice {
  _key?: string;
  restaurantId: string;
  date: number; // booking date
  functionId: string;
  paymentScheduleId: string;
  amount: number;
  description?: string;
  dueDate: number; // YYYYMMDD
  issuedDate: string; // YYYYMMDD
  invoiceNumber: number;
  gstAmount?: number;
  emailSent?: boolean;
}

export interface CreditNote {
  amount: number;
  description: string;
  balance?: number;
  invoiceNumber?: number;
  noteNumber?: number;
  gstAmount?: number;
  issuedDate: string; // YYYYMMDD
  emailSent?: boolean;
}

export interface FunctionPaymentInvoicePdfDetail {
  balanceAmount: number;
  invoiceNumber: number;
  issueDate: string;

  dueDate: string;
  description: string;
  amount: number;
  gstAmount?: number;
}

export interface FunctionFormValidationFlag {
  timeline?: boolean;
  details?: boolean;
  food?: boolean;
  beverage?: boolean;
  foodAndBeverage?: boolean;
  addItems?: boolean;
}

export type FunctionMenuItemsType = "adult" | "child" | "beverageConsumption" | "barTab"