import { StockLimits } from '../../resbutler-utils/types/product';

export const UPDATE_STOCK_LIMIT = 'UPDATE_STOCK_LIMIT';

// export interface StockLimitReducerTypes {
//   count: { products: number; modifiers: number; additions: number; specials: number };
//   products: Products;
//   modifiers: OrderModifiers;
//   additions: OrderAdditions;
//   specials: {};
// }

export const initialStockLimits: StockLimits = { count: { products: 0, modifiers: 0, additions: 0, specials: 0, upsells: 0, upgrades: 0 }, products: {}, modifiers: {}, additions: {}, specials: {}, upsells: {} };

export default function stockLimits(state = initialStockLimits, action) {
  switch (action.type) {
    case UPDATE_STOCK_LIMIT: {
      return action.data;
    }
    default:
      return state;
  }
}
