import { combineReducers } from 'redux';
import { StockLimits } from '../../resbutler-utils/types/product';
import rootSlice, { RootReducerTypes } from './rootSlice';
import settingsReducer, { SettingReducerTypes } from './settings.reducer';
import stockLimitReducer from './stockLimit.reducer';
import themesReducer, { ThemeReducerTypes } from './themes.reducers';
export interface StateType {
  root: RootReducerTypes;
  settings: SettingReducerTypes;
  theme: ThemeReducerTypes;
  stockLimits: StockLimits;
}
export default combineReducers({
  root: rootSlice,
  settings: settingsReducer,
  theme: themesReducer,
  stockLimits: stockLimitReducer,
});
