import React from 'react';
import { Spinner } from 'reactstrap';

const ToggleOptions = (props) => {
  const { onClick, className, style, on, off, active, width, disabled, loading } = props;
  return (
    <>
      <label className={`switch ${className ? className : ''}`} style={style}>
        <input type="checkbox" checked={active} onChange={onClick} disabled={disabled || loading} />
        <span style={{ color: active ? 'white' : null, borderRadius: 4, width, boxShadow: 'none' }}>{`${active === true ? on : off}`}</span>
      </label>
      {loading ? <Spinner size="sm">{""}</Spinner> : null}
    </>
  );
};

ToggleOptions.defaultProps = {
  disabled: false,
};

export default ToggleOptions;
