import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function TableLinkModal({ setShowModal, tableNumbers, linkedTable, handleTableOnClick }) {
  const [filter, setFilter] = useState(null);
  const [filteredTableNumbers, setFilteredTableNumbers] = useState(tableNumbers);

  const onChangeFilter = (e) => {
    let v = '';
    const {
      target: { value },
    } = e;
    if (value.charAt(0) === 't' || value.charAt(0) === 'T') {
      v = value.slice(1);
    } else {
      v = value;
    }
    setFilter(value);
    setFilteredTableNumbers(tableNumbers.filter((tableNumber) => tableNumber.startsWith(v)));
  };

  return (
    <Modal top scrollable size="lg" isOpen toggle={() => setShowModal(false)}>
      <ModalHeader toggle={() => setShowModal(false)}>Table Link</ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row">
            <input id="search" type="search" className="form-control" value={filter} placeholder="Search table number" onChange={onChangeFilter} />
          </div>
          <div className="row">
            {filteredTableNumbers.map((tableNumber) => {
              const isActive = linkedTable === tableNumber;
              return (
                <div key={tableNumber} className="col-3 ">
                  <Button className="w-100 mt-2 mb-2" color={isActive ? 'danger' : 'secondary'} onClick={() => handleTableOnClick(tableNumber, isActive)}>
                    T{tableNumber}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setShowModal(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

export default TableLinkModal;
