/**
 * Extract sizes based on product associated size Ids
 *
 * @param  {object[array]} sizes product sizes
 * @param  {string[]} sizeIds product size Ids
 *
 * @returns {object[]} product sizes detail
 */
export function getSizeDetail(sizes = {}, sizeIds) {
  const productSizes = [];

  if (!sizeIds.length) {
    return sizes;
  }

  for (let sizeId of sizeIds) {
    const size = sizes?.[sizeId];

    if (size?.name) {
      productSizes.push({ ...size, id: sizeId });
    }
  }

  return productSizes;
}
