/* eslint-disable no-unsafe-optional-chaining */
import { assign, keys, map, startsWith } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { Badge, Button, Spinner, Table } from 'reactstrap';
import { Menus } from '../../../../resbutler-utils/types/Menu';
import { Product, ProductSizes, StockLimits } from '../../../../resbutler-utils/types/product';
import UpsellForm from '../common/ProductForm';
import { useAppSelector } from './../../../../hooks/hook';
import { FilterProps } from './../../Filter';

interface UpsellProps {
  menus: Menus;
  restaurantId: string;
  filteredProducts: Product[];
  loading?: boolean;
  filters?: FilterProps;
  productSizes?: ProductSizes;
}

function Upsell({ menus, restaurantId, filteredProducts, loading, filters, productSizes }: UpsellProps) {
  const values = useFormState().values as StockLimits;
  const [collapse, setCollapse] = useState({});
  const [upsellProducts, setUpsellProducts] = useState<Product[]>([]);
  const [filterProducts, setFilterProducts] = useState<Product[]>([]);
  const { upsellGroups, allProducts } = useAppSelector((state) => ({
    upsellGroups: state.root.upsellGroups,
    allProducts: state.root.allProducts,
  }));

  const getUpsellGroup = (upsellGroupId: string) => upsellGroups?.find((item) => item.id == upsellGroupId);
  const getProduct = (productId: string) => allProducts?.find((item) => item.id == productId);

  useEffect(() => {
    let upsellProducts1 = [] as Product[];
    const productsWithUpsells = filteredProducts.filter((item) => item.upsells?.length);
    for (let { upsells = [] } of productsWithUpsells) {
      for (let { upsellGroupId } of upsells) {
        const upsellGroup = getUpsellGroup(upsellGroupId);
        if (upsellGroup && upsellGroup.products.length) {
          for (let { productId, sizes } of upsellGroup.products) {
            const product = getProduct(productId);
            if (product && !upsellProducts1.find((item) => item.id == productId)) {
              const productObj = assign({}, product) as Product;
              productObj.sizes = sizes;
              upsellProducts1.push(productObj);
            }
          }
        }
      }
    }
    setUpsellProducts(upsellProducts1);
  }, [filteredProducts, allProducts, upsellGroups]);

  useEffect(() => {
    let result = [...upsellProducts];
    if (filters.query && filters.query.trim()?.length) {
      result = result.filter(({ name }: any) => startsWith(name.toLowerCase(), filters.query.trim().toLowerCase()));
    }
    setFilterProducts(result);
  }, [filters.query, upsellProducts]);

  // manage accordion for product
  function toggleCollapse(tab) {
    const tab_state = collapse?.[tab] ?? false;
    setCollapse({ ...collapse, [tab]: !tab_state });
  }

  return (
    <React.Fragment>
      <Table bordered className="sc-table">
        <tbody>
          {!loading ? (
            map(filterProducts, ({ id, sizes, name }: Product) => {
              return (
                <React.Fragment key={id}>
                  <tr>
                    <td>
                      <Button type="button" className="mr-2" onClick={() => toggleCollapse(id)}>
                        <i className={!collapse[id] ? 'fa fa-chevron-right' : 'fa fa-chevron-down'} aria-hidden="true" />
                      </Button>
                      {name}
                      <Badge color="light" className="ml-1">
                        {id in values?.upsells && Object.values(values.upsells[id]).some(({ isOverride = false }: any) => !!isOverride) ? 'override' : ''}
                      </Badge>
                    </td>
                  </tr>
                  <tr className={collapse[id] ? 'p-2 d-block' : 'd-none'}>
                    {map(keys(sizes || {}), (sizeId: string) => {
                      return sizes[sizeId] && productSizes?.[sizeId] ? <UpsellForm stockLimitKey="upsells" productId={id} menus={menus} size={productSizes[sizeId]} key={id + sizeId} restaurantId={restaurantId} /> : null;
                    })}
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <Spinner size="sm" className="mr-2">{""}</Spinner>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
}

export default Upsell;
